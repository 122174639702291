<script setup>
import {Link} from '@inertiajs/vue3'
import {onMounted, onUnmounted, ref} from 'vue'
import moment from 'moment'
import Backend from "@/Components/Backend.vue";

const props = defineProps({
    csrfToken: String,
    flash: [],
    contents: [],
    sort: String,
    direction: String,
    links: [],
})

const direction = ref(0)

const defaultClass = Object({
    id: '',
    name: '',
})

function formatDate(date) {
    return moment(date).format("YYYY-MM-DD")
}

function changeDirection(p) {
    if (direction === 'desc') {
        return 'asc';
    } else {
        return 'desc';
    }
}

onMounted(() => {
    console.log('Component contents/index onMounted hook called')
})

</script>

<template>
    <Backend>
        <div class="card">
            <div class="card-header">
                Liste contenu du site
            </div>
            <div class="card-body">
                <Link as="button" :href="links.create_url" class="btn btn-primary">Nouveau contenu</Link>
            </div>
            <div class="card-content">
                <div class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                        <tr>
                            <th>Titre de la page</th>
                            <th>Types</th>
                            <th>Etat</th>
                            <th> Menu</th>
                            <th>...</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="content in contents">
                            <td>
                                <Link :href="content.url_link">
                                    {{ content.url_name }}
                                </Link>
                            </td>
                            <td>{{ content.content_type_name }}</td>
                            <td>{{ content.etat }}</td>
                            <td>{{ content.in_menu }}</td>
                            <td>
                                <Link :href="content.edit_url" class="">
                                    <i class="mdi mdi-pencil text-warning"></i>
                                </Link>
                                <Link :href="content.view_url" class="mx-3">
                                    <i class="mdi mdi-eye text-info"></i>
                                </Link>
                                <Link :href="content.delete_url" class="">
                                    <i class="mdi mdi-trash-can text-danger"></i>
                                </Link>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </Backend>
</template>
