<script setup>
import {Link, usePage} from '@inertiajs/vue3'
import {onMounted, ref} from "vue";

const isOpen = ref(false)
const userlogged = ref('')

const toggleDropdown = () =>  {
    isOpen.value = !isOpen.value;
}

const props = defineProps({
    username: String,
})

onMounted(() => {
    const page = usePage();
    //toggleDropdown
    userlogged.value = page.props.username;
    console.log(userlogged);
});
</script>

<template>
    <main class="wrapper">
        <aside class="left-sidebar sidebar-dark" id="left-sidebar">
            <div id="sidebar" class="sidebar sidebar-with-footer">
                <!-- Aplication Brand -->
                <div class="app-brand">
                    <Link href="/dashboard">
                        <span class="brand-name">TISAN CORP</span>
                    </Link>
                </div>
                <!-- begin sidebar scrollbar -->

                <div class="sidebar-left" data-simplebar style="height: 100%;">
                    <ul class="nav sidebar-inner" id="sidebar-menu">
                        <li class="active">
                            <Link class="sidenav-item-link" href="/dashboard">
                                <i class="mdi mdi-briefcase-account-outline"></i>
                                <span class="nav-text">Tableau de bord</span>
                            </Link>
                        </li>
                        <li class="section-title">
                            Site et Contenu
                        </li>
                        <li class="">
                            <Link class="sidenav-item-link" href="/contents/pages/2">
                                <i class="mdi mdi-briefcase-account-outline"></i>
                                <span class="nav-text">Pages</span>
                            </Link>
                        </li>
                        <li class="">
                            <Link class="sidenav-item-link" href="/contents/actualites/18">
                                <i class="mdi mdi-briefcase-account-outline"></i>
                                <span class="nav-text">Actualités</span>
                            </Link>
                        </li>
                        <li class="">
                            <Link class="sidenav-item-link" href="/contents/services/10">
                                <i class="mdi mdi-briefcase-account-outline"></i>
                                <span class="nav-text">Nos Services</span>
                            </Link>
                        </li>
                        <li class="">
                            <Link class="sidenav-item-link" href="/slides">
                                <i class="mdi mdi-briefcase-account-outline"></i>
                                <span class="nav-text">Slides</span>
                            </Link>
                        </li>
                        <li class="">
                            <Link class="sidenav-item-link" href="/utilisateurs">
                                <i class="mdi mdi-briefcase-account-outline"></i>
                                <span class="nav-text">Utilisateurs</span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </aside>
        <div class="page-wrapper">
            <!-- Header -->
            <header class="main-header" id="header">
                <nav class="navbar navbar-expand-lg navbar-light" id="navbar">
                    <!-- Sidebar toggle button -->
                    <button id="sidebar-toggler" class="sidebar-toggle">
                        <span class="sr-only">Toggle navigation</span>
                    </button>

                    <span class="page-title">Ti-San Corporation</span>

                    <div class="navbar-right ">

                        <ul class="nav navbar-nav">
                            <!-- User Account -->
                            <li class="dropdown user-menu">
                                <button class="dropdown-toggle nav-link" data-toggle="dropdown" @click="toggleDropdown" >
<!--                                    <img src="images/user/user-xs-01.jpg" class="user-image rounded-circle" alt="User Image"/>-->
                                    <span class="d-none d-lg-inline-block">{{ userlogged }}</span>
                                </button>
                                <ul class="dropdown-menu dropdown-menu-right" :class="{ show: isOpen }">
                                    <!--
                                    <li>
                                        <a class="dropdown-link-item" href="/user/profile">
                                            <i class="mdi mdi-account-outline"></i>
                                            <span class="nav-text">Mon Profile</span>
                                        </a>
                                    </li> -->
                                    <li class="dropdown-footer">
                                        <a class="dropdown-link-item" href="/logout">
                                            <i class="mdi mdi-logout"></i> Se Déconnecté
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </nav>
            </header>
            <div class="content-wrapper">
                <div class="content">
                    <slot/>
                </div>
            </div>
            <footer class="footer mt-auto">
            </footer>
        </div>
    </main>
</template>
