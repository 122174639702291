<script setup>
import Layout from '../Layout'
import {Link, useForm} from '@inertiajs/vue3'
import {onMounted, onUnmounted, ref} from 'vue'
import moment from 'moment'
import sanitizeHtml from "sanitize-html";
import Backend from "@/Components/Backend.vue";

const props = defineProps({
    csrfToken: String,
    types: String,
    content: [],
    parent: [],
})

const form = useForm({
    id: props.content.id,
    name: props.types,
    image: null,
})

const bannerform = useForm({
    id: props.content.id,
    name: 'banner',
    image: null,
})

function submit() {
    form.post('/contents/uploadfile')
}

function submitBanner() {
    bannerform.post('/contents/uploadfile')
}

function formatDate(date) {
    return moment(date).format("YYYY-MM-DD")
}

onMounted(() => {
    console.log('Component categories/add onMounted hook called')
})
</script>

<template>
    <Backend>
        <div class="card">
            <div class="card-header">
                Détail {{ parent.content_description.name }}
            </div>
            <div class="card-body">
                <div class="column-responsive column-80">
                    <div class="contents view content">
                        <h3 class="mb-3">Enregistrement N° {{ content.id }}</h3>
                        <table>
                            <tr>
                                <th>Id</th>
                                <td> {{ content.id }}</td>
                            </tr>
                            <tr>
                                <th>Parent Id</th>
                                <td>
                            <span v-if="content.parent_content">
                                <Link as="button" class="" :href="'/contents/view/' + content.parent_content.id">
                                    {{ content.parent_content.id }}
                                </Link>
                            </span>
                                </td>
                            </tr>
                            <tr>
                                <th>Code64cc</th>
                                <td>{{ content.code64cc }}</td>
                            </tr>
                            <tr>
                                <th>Slug</th>
                                <td>{{ content.slug }}</td>
                            </tr>
                            <tr>
                                <th>Slug</th>
                                <td>{{ content.content_description.name }}</td>
                            </tr>
                            <tr>
                                <th>Description</th>
                                <td v-html="sanitizeHtml(content.content_description.description)"></td>
                            </tr>
                            <tr>
                                <th>Description court</th>
                                <td v-html="sanitizeHtml(content.content_description.short_description)"></td>
                            </tr>
                            <tr>
                                <th>Ordering</th>
                                <td> {{ content.ordering }}
                                </td>
                            </tr>
                            <tr>
                                <th>Created By</th>
                                <td> {{ content.created_by }}
                                </td>
                            </tr>
                            <tr>
                                <th>Modified By</th>
                                <td> {{ content.modified_by }}
                                </td>
                            </tr>
                            <tr>
                                <th>Dt Created</th>
                                <td> {{ formatDate(content.dt_created) }}
                                </td>
                            </tr>
                            <tr>
                                <th>Dt Modified</th>
                                <td> {{ formatDate(content.dt_modified) }}
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <hr>

                <h3 class="mb-3">Gestion des images</h3>

                <form @submit.prevent="submit">
                    <input type="hidden" name="id" v-model="form.id" readonly="readonly"/>

                    <div class="row mb-3">
                        <div class="col-md-12 mb-3">
                            <input type="hidden" value="" name="name[]" v-model="form.name" readonly="readonly"/>
                            <label class="">Image à la une</label>
                            <input type="file" name="image[]" class="form-control"
                                   @input="form.image = $event.target.files[0]"/>

                            <progress v-if="form.progress" :value="form.progress.percentage" max="100">
                                {{ form.progress.percentage }}%
                            </progress>
                        </div>
                        <div class="col-md-12">
                            <button type="submit" class="btn btn-primary">Enregistrer</button>
                        </div>
                    </div>
                </form>

                <form @submit.prevent="submitBanner">
                    <input type="hidden" name="id" v-model="bannerform.id" readonly="readonly"/>
                    <div class="row mb-3">
                        <div class="col-md-12 mb-3">
                            <input type="hidden" value="banner" name="name[]" v-model="bannerform.name"
                                   readonly="readonly"/>
                            <label class="">Image bannière</label>
                            <input type="file" name="image[]" class="form-control"
                                   @input="bannerform.image = $event.target.files[0]"/>

                            <progress v-if="bannerform.progress" :value="bannerform.progress.percentage" max="100">
                                {{ form.progress.percentage }}%
                            </progress>
                        </div>
                        <div class="col-md-12">
                            <button type="submit" class="btn btn-primary">Enregistrer</button>
                        </div>
                    </div>

                </form>
            </div>
        </div>

    </Backend>
</template>

