<script setup>
import {Link, usePage} from '@inertiajs/vue3'
import BreakingNews from "@/Shared/BreakingNews.vue";
import HeaderTop from "@/Shared/HeaderTop.vue";
import Navbar from "@/Shared/Navbar.vue";
import {Inertia} from "@inertiajs/inertia";
import {onMounted, ref} from "vue";
import Footer from "@/Shared/Footer.vue";


const confiqData = ref({});

const components = {
    BreakingNews,
    HeaderTop,
    Navbar,
}

const props = defineProps({
})



onMounted(() => {
    const page = usePage();

    confiqData.value = page.props.config

})
</script>


<template>
    <main class="wrapper">
        <header class="header">
            <HeaderTop :config="confiqData" />
            <Navbar />
        </header>

        <div class="main">
            <slot/>
        </div>

        <Footer :footer="confiqData" />

    </main>
</template>
