<script setup>
import Layout from "@/Components/Layout.vue";
import {onMounted, reactive, ref} from "vue";
import {usePage, Link} from "@inertiajs/vue3";

const configApp = ref([])

const props = defineProps({
    content: [],
    config: [],
})

onMounted(() => {
    const page = usePage();

    configApp.value = page.props.config
});

</script>

<template>
    <Layout>
        <section className="banner-page">
            <div className="content-wrap pos-relative">
                <div className="container">
                    <div className="col-12 col-md-12">
                        <div className="d-flex bd-highlight mb-2">
                            <div className="title-page">{{ content.content_description.name }}</div>
                        </div>
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb text-white ">
                                <li class="breadcrumb-item">
                                    <Link href="/">Accueil</Link>
                                </li>
                                <li class="breadcrumb-item active" aria-current="page">{{ content.content_description.name }}</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </section>
        <section id="" className="conact">
            <div className="content-wrap">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-4">
                            <h4 className="text-black mt-0">Contact Details</h4>
                            <div className="rs-icon-info-2">
                                <div className="info-icon">
                                    <i className="fa fa-map-marker"></i>
                                </div>
                                <div className="body-text">
                                    <h5>Siège Social</h5>
                                    <p>{{ configApp.SIEGE_SOCIAL }}</p>
                                </div>
                            </div>
                            <div className="rs-icon-info-2">
                                <div className="info-icon">
                                    <i className="fa fa-phone"></i>
                                </div>
                                <div className="body-text">
                                    <h5>Phone</h5>
                                    <p>
                                        {{ configApp.TEL_NUM }} <br/>
                                        {{ configApp.MOBILE_NUM }}
                                    </p>
                                </div>
                            </div>
                            <div className="rs-icon-info-2">
                                <div className="info-icon">
                                    <i className="fa fa-envelope"></i>
                                </div>
                                <div className="body-text">
                                    <h5>Email</h5>
                                    <p>{{ configApp.MAIL_INFO }}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-7 offset-lg-1">
                            <h4 className="text-black mt-0">Nous écrire ici</h4>
                            <form action="#" className="form-contact" id="contactForm">
                                <div className="row mb-3">
                                    <div className="col-sm-6 col-md-6">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="p_name"
                                                placeholder="Enter Name"
                                                required=""/>
                                            <div className="help-block with-errors"></div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-6">
                                        <div className="form-group">
                                            <input
                                                type="email"
                                                className="form-control"
                                                id="p_email"
                                                placeholder="Enter Email"
                                                required=""/>
                                            <div className="help-block with-errors"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-sm-6 col-md-6">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="p_subject"
                                                placeholder="Subject"/>
                                            <div className="help-block with-errors"></div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-6">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="p_phone"
                                                placeholder="Enter Phone Number"/>
                                            <div className="help-block with-errors"></div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <div className="col-sm-12">
                                            <textarea
                                                id="p_message"
                                                className="form-control"
                                                rows="6"
                                                placeholder="Enter Your Message"></textarea>
                                        <div className="help-block with-errors"></div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-sm-12">
                                        <div className="text-left">
                                            <div id="success"></div>
                                            <button type="submit" className="btn btn-primary">Envoyer votre Message
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Layout>
</template>

<style scoped>

</style>
