<script setup>

import Layout from "@/Components/Layout.vue";
import {onMounted, reactive, ref} from "vue";
import {Link, usePage} from "@inertiajs/vue3";
import ServiceItems from "@/Shared/ServiceItems.vue";
import sanitizeHtml from "sanitize-html";
import LastActualites from "@/Shared/LastActualites.vue";

const confiqData = ref({});

const bannerStyle = reactive({
    backgroundImage: "url()",
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: '100% 100%',
    width: '100%',
    height: '300px',
    position: 'relative',
})

const props = defineProps({
    config: [],
    parent: [],
    content: [],
    services: [],
    lastActualites: [],
})

const updateStyles = (param) => {
    bannerStyle.backgroundImage = "url(" + param + ")";
}

onMounted(() => {
    const page = usePage();

    confiqData.value = page.props.config

    updateStyles(props.content.banner);
})

</script>

<template>
    <Layout>
        <div class="section banner-page" :data-background="`/files/${content.banner}`" :style="bannerStyle">
            <div class="content-wrap pos-relative">
                <div class="container">
                    <div class="col-12 col-md-12">
                        <div class="d-flex bd-hidhlight mb-2">
                            <div class="title-page">
                                {{ content.title }}
                            </div>
                        </div>
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb text-white ">
                                <li class="breadcrumb-item">
                                    <Link href="/">Accueil</Link>
                                </li>
                                <li v-if="parent.slug === `actualites`" class="breadcrumb-item">
                                    <Link :href="`/actualites`">Actualites</Link>
                                </li>
                                <li class="breadcrumb-item active" aria-current="page">{{ content.title }}</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
        <section id="section" class="section">
            <div class="content-wrap">
                <div class="container">
                    <div class="row ">
                        <div class="col-12 col-sm-12 col-md-8">
                            <img :src="content.imageurl" class="img-fluid"/>
                            <h3 class="text-primary my-4">{{ content.title }}</h3>
                            <div v-html="sanitizeHtml(content.content)"></div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4">
                            <ServiceItems v-if="parent.slug !== `actualites`" :serviceProps="services" />
                            <LastActualites v-else-if="parent.slug === `actualites`" :actualiteProps="lastActualites" />
                            <div class="widget download">
                                <div class="row">
                                    <div class="col-12">
                                        <a href="" class="btn btn-success btn-block">
                                            <i class='bx bxs-file-pdf'></i> Télécharger notre brochure
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                    <h4 class="text-black mt-0">Contact Details</h4>
                                    <div class="rs-icon-info-2">
                                        <div class="info-icon">
                                            <i class="fa fa-map-marker"></i>
                                        </div>
                                        <div class="body-text">
                                            <p>{{ confiqData.SIEGE_SOCIAL }}</p>
                                        </div>
                                    </div>
                                    <div class="rs-icon-info-2">
                                        <div class="info-icon">
                                            <i class="bx bx-phone"></i>
                                        </div>
                                        <div class="body-text">
                                            <p class="mb-0">
                                                {{ confiqData.TEL_NUM }}
                                            </p>
                                            <p>
                                                {{ confiqData.MOBILE_NUM }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="rs-icon-info-2">
                                        <div class="info-icon">
                                            <i class="bx bx-envelope"></i>
                                        </div>
                                        <div class="info-text">
                                            <p>
                                                {{ confiqData.MAIL_INFO }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Layout>
</template>

<style scoped>

</style>
