<script setup>

import Frontend from "@/Components/Frontend.vue";
import {Link} from '@inertiajs/vue3'
import {onMounted, onUnmounted, ref} from 'vue'
import moment from 'moment'
import Layout from "@/Components/Layout.vue";

defineProps({
    csrfToken: String,
    flash: Array,
    page: Array,
})


onMounted(() => {
    console.log('Component Dashboard onMounted hook called')
})

</script>

<template>
    <Layout>
        demo
    </Layout>
</template>

<style scoped>

</style>
