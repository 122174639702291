<script setup>
import Backend from "@/Components/Backend.vue";
import TextareaInput from "@/Shared/TextareaInput.vue";
import {VueEditor} from "vue3-editor";
import {reactive, onMounted, onUnmounted, ref} from 'vue'
import {Link, router} from '@inertiajs/vue3'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Datepicker from 'vue3-datepicker';
import Multiselect from '@vueform/multiselect';

const editor = ref(ClassicEditor);
const editorConfig = reactive({
    toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|', 'outdent', 'indent', '|', 'insertTable', 'blockQuote', '|', 'imageUpload', 'mediaEmbed', '|', 'undo', 'redo'],
    table: {
        toolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
    },
    extraPlugins: [uploader],
});

const props = defineProps({
    csrfToken: String,
    flash: [],
    content: [],
    errors: [],
    parent_id: String,
    content_type_id: String,
})

const components = {
    VueEditor,
    Datepicker,
    Multiselect,
}

const form = reactive({
    parent_id: props.parent_id,
    content_type_id: props.content_type_id,
    code64cc: null,
    name: null,
    slug: null,
    ordering: null,
    show_in_menu: null,
    description: null,
    short_description: null,
    meta_title: null,
    meta_description: null,
    meta_keywords: null,
    is_active: null,
})

function submit() {
    let postUrl = '/contents/add';
    router.post(postUrl,
        {
            parent_id: form.parent_id,
            content_type_id: form.content_type_id,
            slug: form.slug,
            name: form.name,
            description: form.description,
            short_description: form.short_description,
            meta_title: form.meta_title,
            meta_keywords: form.meta_keywords,
            meta_description: form.meta_description,
            ordering: form.ordering,
            show_in_menu: form.show_in_menu,
            is_active: form.is_active,
        },

        {
            headers: {
                'X-CSRF-Token': props.csrfToken
            }
        }
    );
}

const uploader = (editor) => {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
        return new UploadAdapter(loader);
    };
}

onMounted(() => {
    console.log('Component contents/add onMounted hook called')
})
</script>

<style src="@vueform/multiselect/themes/default.css"></style>

<template>
    <Backend>
        <div class="card">
            <div class="card-header">
            </div>
            <div class="card-body">

                <div class="contents form content">
                    <h3>Nouveau contenu</h3>
                    <form @submit.prevent="submit">

                        <input type="hidden" readonly="readonly" id="parent_id" v-model="form.parent_id" class="form-control" />
                        <input type="hidden" readonly="readonly" id="content_type_id" v-model="form.content_type_id" class="form-control" />

                        <div class="form-group">
                            <label for="name">Titre contenu:</label>
                            <input id="name" v-model="form.name" class="form-control"/>
                            <div class="message error" v-if="props.errors.name">{{ props.errors.name._empty }}</div>
                        </div>

                        <div class="form-group">
                            <label for="slug">Lien url contenu:</label>
                            <input id="slug" v-model="form.slug" class="form-control"/>
                            <div class="message error" v-if="props.errors.slug">{{ props.errors.slug._empty }}</div>
                        </div>

                        <div class="form-group">
                            <label for="description">Description Courte:</label>
                            <ckeditor :editor="editor" :toolbar="toolbar" id="short_description"
                                      v-model="form.short_description" class="form-control" aria-rowcount="5"/>
                            <div class="message error" v-if="props.errors.short_description">
                                {{ props.errors.short_description._empty }}
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="description">Description:</label>
                            <ckeditor :editor="editor" :toolbar="toolbar" id="description" v-model="form.description"
                                      class="form-control" aria-rowcount="5"/>
                            <div class="message error" v-if="props.errors.description">
                                {{ props.errors.description._empty }}
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="meta_title">Titre meta:</label>
                            <input id="meta_title" v-model="form.meta_title" class="form-control"/>
                            <div class="message error" v-if="props.errors.meta_title">{{
                                    props.errors.meta_title._empty
                                }}
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="meta_description">Meta description:</label>
                            <input id="meta_description" v-model="form.meta_description" class="form-control"/>
                            <div class="message error" v-if="props.errors.meta_description">
                                {{ props.errors.meta_description._empty }}
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="meta_keywords">Clef Meta:</label>
                            <input id="meta_keywords" v-model="form.meta_keywords" class="form-control"/>
                            <div class="message error" v-if="props.errors.meta_keywords">
                                {{ props.errors.meta_keywords._empty }}
                            </div>
                        </div>

                        <div class="form-group">
                            <div
                                class="custom-control custom-checkbox checkbox-outline-secondary d-inline-block mr-3">
                                <input id="show_in_menu" v-model="form.show_in_menu" class="custom-control-input"
                                       type="checkbox"/>
                                <label for="show_in_menu" class="custom-control-label">Voir dans le menu?</label>
                                <div class="message error" v-if="props.errors.show_in_menu">
                                    {{ props.errors.show_in_menu._empty }}
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <div
                                class="custom-control custom-checkbox checkbox-outline-secondary d-inline-block mr-3">
                                <input id="is_active" v-model="form.is_active" class="custom-control-input"
                                       type="checkbox"/>
                                <label for="is_active" class="custom-control-label">Mettre en ligne</label>
                                <div class="message error" v-if="props.errors.is_active">{{
                                        props.errors.is_active._empty
                                    }}
                                </div>
                            </div>
                        </div>

                        <button type="submit" class="btn btn-primary float-right">Enregistrer</button>
                    </form>
                </div>
            </div>
        </div>
    </Backend>
</template>
