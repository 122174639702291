<script setup>
import Frontend from "@/Components/Frontend.vue";
import {Link, Head} from '@inertiajs/vue3'
import {onMounted, onUnmounted, ref} from 'vue'
import moment from 'moment'
import Layout from "@/Components/Layout.vue";
import SlidShow from "@/Shared/SlidShow.vue";
import AboutUs from "@/Shared/AboutUs.vue";
import ServicesBox from "@/Shared/ServicesBox.vue";
import TestmonialsBox from "@/Shared/TestmonialsBox.vue";
import ActualitiesBox from "@/Shared/ActualitiesBox.vue";

const props = defineProps({
    csrfToken: String,
    title: String,
    flash: [],
    services: [],
    aboutus: [],
    actualities: [],
    partners: [],
})

const setPageTitle = function (title) {
    document.title = title;
};
onMounted(() => {
    console.log('Component Dashboard onMounted hook called');
    setPageTitle('T-SANCORP | Page d\'Accueil');
})
</script>

<template>
    <Head>
        <title>Demo</title>
    </Head>
    <Layout>
        <SlidShow />
        <AboutUs :aboutdata="aboutus" />
        <ServicesBox :servicesData="services" />
        <TestmonialsBox />
        <ActualitiesBox :actualitiesData="actualities" v-if="actualities.length" />
    </Layout>
</template>
