<script setup>

import Layout from "@/Components/Layout.vue";
import sanitizeHtml from "sanitize-html";
import {onMounted, reactive} from "vue";
import ExperienceBox from "@/Shared/ExperienceBox.vue";

const props = defineProps({
    title: String,
    content: []
});

const bannerStyle = reactive({
    backgroundImage: "url()",
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: '100% 100%',
    width: '100%',
    height: '300px',
    position: 'relative',
})

const updateStyles = (param) => {
    bannerStyle.backgroundImage = "url(/files/" + param + ")";
}

onMounted(() => {
    updateStyles(props.content.banner);
});

</script>

<template>
    <Layout>
        <div class="section banner-page" :data-background="`/files/${content.banner}`" :style="bannerStyle">
            <div class="content-wrap pos-relative">
                <div class="container">
                    <div class="col-12 col-md-12">
                        <div class="d-flex bd-hidhlight mb-2">
                            <div class="title-page">
                                {{ content.description.title }}
                            </div>
                        </div>
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb ">
                                <li class="breadcrumb-item"><a href="/">Accueil</a></li>
                                <li class="breadcrumb-item active" aria-current="page">{{ content.description.title }}</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
        <section id="section" class="section">
            <div class="content-wrap pb-0">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-md-4 col-lg-4">
                            <img :src="content.description.imageurl" alt="" class="img-fluid">
                        </div>
                        <div class="col-12 col-md-7 offset-md-1"
                             v-html="sanitizeHtml(content.description.content)"></div>
                    </div>
                </div>
            </div>

            <ExperienceBox v-if="content.urllink === `qui-sommes-nous`"/>
        </section>
    </Layout>
</template>

<style scoped>

</style>
