<script setup>
import {Link, usePage} from "@inertiajs/vue3";
import {computed, onMounted, ref} from "vue";

const menuItem = ref(false);
const subMenu = ref(false);
const menuVisible = ref({});

const config_arr = ref([]);

const props = defineProps({
    flash: [],
    data: [],
    config: [],
})
/**/

onMounted(() => {
    const page = usePage();
    //const sharedata = computed(() => page.props.sharedata);
    const menuList = computed(() => page.props.data).value;
    subMenu.value = JSON.parse(menuList);

    config_arr.value = page.props.config

    let i = 0;
    Object.keys(subMenu).forEach(menu => {
        menuVisible.value[i++] = ref(false);
    });
});

</script>

<template>
    <div class="header-navbar">
        <div class="container-fluid">
            <nav class="navbar navbar-expand-lg navbar-light bg-light">
                <Link class="navbar-brand" href="/">
                    <img :src="config_arr.SITE_LOGO" class="navbar-logo" alt="Logo Ti-San"/>
                </Link>
                <button
                    class="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav ml-auto">
                        <li class="nav-item">
                            <Link class="nav-link" aria-current="page" href="/">
                                <i class="bx bx-home"></i> Accueil
                            </Link>
                        </li>
                        <li
                            v-for="(menu_item, m_index) in subMenu"
                            :key="m_index"
                            :class="{'nav-item': true, 'dropdown': menu_item.children.length}">
                            <a v-if="menu_item.children.length" href="#" :class="{'nav-link': true, 'dropdown-toggle': menu_item.children.length} ">
                                {{ menu_item.title }}
                            </a>
                            <Link v-else :href="menu_item.url" :class="{'nav-link': true, 'dropdown-toggle': menu_item.children.length} ">
                                {{ menu_item.title }}
                            </Link>
                            <ul class="dropdown-menu" v-if="menu_item.children.length">
                                <li class="nav-item" v-for="(sub_menu, sm_index) in menu_item.children" :key="sm_index">
                                    <Link class="nav-link" :href="sub_menu.url">{{ sub_menu.title }}</Link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>

</template>

<style scoped>

</style>
