<script setup>
    import { reactive, onMounted, onUnmounted, ref } from 'vue'
    import Backend from "@/Components/Backend.vue";
    import { router } from '@inertiajs/vue3'
    import { VueEditor } from "vue3-editor";
    import Datepicker from 'vue3-datepicker';
    import Multiselect from '@vueform/multiselect'

    const props = defineProps({
        csrfToken: String,
        flash: [],
        user: [],
        errors: [],

     })

    const components = {
        VueEditor, Datepicker, Multiselect
    }

    const form = reactive({
        username : null,
        email : null,
        password : null,
        first_name : null,
        last_name : null,
        token : null,
        token_expires : null,
        api_token : null,
        activation_date : null,
        secret : null,
        secret_verified : null,
        tos_date : null,
        last_login : null,
        active : null,
        is_superuser : null,
        role : null,
        created : null,
        modified : null,
        additional_data : null,

    })

    function submit() {
        let postUrl = '/users/add';
        router.post(postUrl,
            {
                username : form.username,
                email : form.email,
                password : form.password,
                first_name : form.first_name,
                last_name : form.last_name,
                token : form.token,
                token_expires : form.token_expires,
                api_token : form.api_token,
                activation_date : form.activation_date,
                secret : form.secret,
                secret_verified : form.secret_verified,
                tos_date : form.tos_date,
                last_login : form.last_login,
                active : form.active,
                is_superuser : form.is_superuser,
                role : form.role,
                created : form.created,
                modified : form.modified,
                additional_data : form.additional_data,
            },
            {
                headers: {
                    'X-CSRF-Token': props.csrfToken
                }
            }
        )
    }
    onMounted(() => {
        console.log('Component users/add onMounted hook called')
    })
</script>

<style src="@vueform/multiselect/themes/default.css"></style>

<template>
    <Backend>
        <div class="row">
            <div class="column-responsive column-80">

                <div onclick="this.classList.add('hidden')" :class="[(flash['element'] === undefined) ? 'hidden' : '','message', (flash['element'] === 'flash-success' ? 'success' : 'error')]">{{ flash['message'] }}</div>
                <div class="users form content">
                    <h3>Add User</h3>
                    <form @submit.prevent="submit">
                        <label for="username">Username:</label>

                        <input id="username" v-model="form.username" />
                        <div class="message error" v-if="props.errors.username">{{ props.errors.username._empty }}</div>


                        <label for="email">Email:</label>

                        <input id="email" v-model="form.email" />
                        <div class="message error" v-if="props.errors.email">{{ props.errors.email._empty }}</div>


                        <label for="password">Password:</label>

                        <input id="password" v-model="form.password" />
                        <div class="message error" v-if="props.errors.password">{{ props.errors.password._empty }}</div>


                        <label for="first_name">First_name:</label>

                        <input id="first_name" v-model="form.first_name" />
                        <div class="message error" v-if="props.errors.first_name">{{ props.errors.first_name._empty }}</div>


                        <label for="last_name">Last_name:</label>

                        <input id="last_name" v-model="form.last_name" />
                        <div class="message error" v-if="props.errors.last_name">{{ props.errors.last_name._empty }}</div>


                        <label for="token">Token:</label>

                        <input id="token" v-model="form.token" />
                        <div class="message error" v-if="props.errors.token">{{ props.errors.token._empty }}</div>


                        <label for="token_expires">Token_expires</label>                            datetime Not Implemented in vueTemplate Baked


                        <label for="api_token">Api_token:</label>

                        <input id="api_token" v-model="form.api_token" />
                        <div class="message error" v-if="props.errors.api_token">{{ props.errors.api_token._empty }}</div>


                        <label for="activation_date">Activation_date</label>                            datetime Not Implemented in vueTemplate Baked


                        <label for="secret">Secret:</label>

                        <input id="secret" v-model="form.secret" />
                        <div class="message error" v-if="props.errors.secret">{{ props.errors.secret._empty }}</div>


                        <label for="secret_verified">Secret_verified:</label>

                        <input id="secret_verified" v-model="form.secret_verified" />
                        <div class="message error" v-if="props.errors.secret_verified">{{ props.errors.secret_verified._empty }}</div>


                        <label for="tos_date">Tos_date</label>                            datetime Not Implemented in vueTemplate Baked


                        <label for="last_login">Last_login</label>
                        <Datepicker v-model="form.last_login" valueType="format" format="YYYY-MM-DD"></Datepicker>

                        <label for="active">Active:</label>

                        <input id="active" v-model="form.active" />
                        <div class="message error" v-if="props.errors.active">{{ props.errors.active._empty }}</div>


                        <label for="is_superuser">Is_superuser:</label>

                        <input id="is_superuser" v-model="form.is_superuser" />
                        <div class="message error" v-if="props.errors.is_superuser">{{ props.errors.is_superuser._empty }}</div>


                        <label for="role">Role:</label>

                        <input id="role" v-model="form.role" />
                        <div class="message error" v-if="props.errors.role">{{ props.errors.role._empty }}</div>


                        <label for="additional_data">Additional_data:</label>

                        <input id="additional_data" v-model="form.additional_data" />
                        <div class="message error" v-if="props.errors.additional_data">{{ props.errors.additional_data._empty }}</div>


                        <button type="submit">Submit</button>
                    </form>
                </div>
            </div>
        </div>
    </Backend>
</template>
