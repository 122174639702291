<script setup>
import {Link} from '@inertiajs/vue3'
import {onMounted, onUnmounted, ref} from 'vue'
import moment from 'moment'
import Backend from "@/Components/Backend.vue";

const props = defineProps({
    csrfToken: String,
    flash: [],
    users: [],
    direction: String,
})

const direction = ref(0)

const defaultClass = Object({
    id: '',
    name: '',
})

function formatDate(date) {
    return moment(date).format("YYYY-MM-DD")
}

function changeDirection(p) {
    if (direction === 'desc') {
        return 'asc';
    } else {
        return 'desc';
    }
}

onMounted(() => {
    console.log('Component users/index onMounted hook called')
})

</script>

<template>
    <Backend>
        <div class="card">
            <div class="card-header">
                Liste contenu du site
            </div>
            <div class="card-body">
                <Link as="button" :href="`/utilisateurs/add`" class="btn btn-primary">Nouveau</Link>
            </div>
            <div class="card-content">
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                        <tr>

                            <th scope="col">
                                <Link href="/users/index" :class="defaultClass.id">Id</Link>
                            </th>

                            <th scope="col">
                                <Link href="/users/index" :class="defaultClass.username">Login</Link>
                            </th>

                            <th scope="col">
                                <Link href="/users/index" :class="defaultClass.email">Email</Link>
                            </th>

                            <th scope="col">
                                <Link href="/users/index" :class="defaultClass.first_name">Prénom(s)</Link>
                            </th>

                            <th scope="col">
                                <Link href="/users/index" :class="defaultClass.last_name">Nom</Link>
                            </th>

                            <th scope="col">
                                <Link href="/users/index" :class="defaultClass.last_login">Dernière Connexion</Link>
                            </th>

                            <th scope="col">
                                <Link href="/users/index" :class="defaultClass.role">Profile</Link>
                            </th>

                            <th class="actions"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="user in users">
                            <td>
                                {{ user.id }}
                            </td>
                            <td>
                                {{ user.username }}
                            </td>
                            <td>
                                {{ user.email }}
                            </td>
                            <td>
                                {{ user.first_name }}
                            </td>
                            <td>
                                {{ user.last_name }}
                            </td>
                            <td>
                                {{ user.last_login }}
                            </td>
                            <td>
                                {{ user.role }}
                            </td>

                            <td class="actions">
                                <Link as="button" class="btn btn-outline-primary btn-xs" method="get" :href="`/utilisateurs/view/${user.id}`">View</Link>
                                <Link as="button" class="btn btn-outline-primary btn-xs" method="get" :href="`/utilisateurs/edit/${user.id}`">Edit</Link>
                                <Link as="button" class="btn btn-outline-primary btn-xs" :data="{ csrfToken:csrfToken }" method="get"
                                      :href="`/utilisateurs/delete/${user.id}`">Delete
                                </Link>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </Backend>
</template>
