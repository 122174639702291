<script setup>
import {Link} from '@inertiajs/vue3'
import Layout from '../Layout'
import {onMounted, onUnmounted, ref} from 'vue'
import moment from 'moment'

const props = defineProps({
    csrfToken: String,
    flash: [],
    contents: [],
    sort: String,
    direction: String,
    links: [],
})

const direction = ref(0)

const defaultClass = Object({
    id: '',
    name: '',
})

function formatDate(date) {
    return moment(date).format("YYYY-MM-DD")
}

function changeDirection(p) {
    if (direction === 'desc') {
        return 'asc';
    } else {
        return 'desc';
    }
}

onMounted(() => {
    console.log('Component contents/index onMounted hook called')
})

</script>

<template>
    <Layout>
        <div class="column-responsive column-80">

            <div onclick="this.classList.add('hidden')"
                 :class="[(flash['element'] === undefined) ? 'hidden' : '','message', (flash['element'] === 'flash-success' ? 'success' : 'error')]">
                {{ flash['message'] }}
            </div>
            <div class="contents index content">
                <h3>Contents</h3>
                <Link as="button" href="/contents/add" class="button shadow radius right small">Add Content</Link>

                <div class="table-responsive">
                    <table>
                        <thead>
                        <tr>

                            <th scope="col">
                                <Link href="/contents/index" :class="defaultClass.id"
                                      :data="{ sort: 'id', direction: changeDirection }">Id
                                </Link>
                            </th>

                            <th scope="col">
                                <Link href="/contents/index" :class="defaultClass.parent_id"
                                      :data="{ sort: 'parent_id', direction: changeDirection }">Parent_id
                                </Link>
                            </th>

                            <th scope="col">
                                <Link href="/contents/index" :class="defaultClass.content_type_id"
                                      :data="{ sort: 'content_type_id', direction: changeDirection }">Content_type_id
                                </Link>
                            </th>

                            <th scope="col">
                                <Link href="/contents/index" :class="defaultClass.code64cc"
                                      :data="{ sort: 'code64cc', direction: changeDirection }">Code64cc
                                </Link>
                            </th>

                            <th scope="col">
                                <Link href="/contents/index" :class="defaultClass.slug"
                                      :data="{ sort: 'slug', direction: changeDirection }">Slug
                                </Link>
                            </th>

                            <th scope="col">
                                <Link href="/contents/index" :class="defaultClass.ordering"
                                      :data="{ sort: 'ordering', direction: changeDirection }">Ordering
                                </Link>
                            </th>

                            <th scope="col">
                                <Link href="/contents/index" :class="defaultClass.show_in_menu"
                                      :data="{ sort: 'show_in_menu', direction: changeDirection }">Show_in_menu
                                </Link>
                            </th>

                            <th scope="col">
                                <Link href="/contents/index" :class="defaultClass.is_active"
                                      :data="{ sort: 'is_active', direction: changeDirection }">Is_active
                                </Link>
                            </th>

                            <th scope="col">
                                <Link href="/contents/index" :class="defaultClass.dt_created"
                                      :data="{ sort: 'dt_created', direction: changeDirection }">Dt_created
                                </Link>
                            </th>

                            <th scope="col">
                                <Link href="/contents/index" :class="defaultClass.created_by"
                                      :data="{ sort: 'created_by', direction: changeDirection }">Created_by
                                </Link>
                            </th>

                            <th scope="col">
                                <Link href="/contents/index" :class="defaultClass.dt_modified"
                                      :data="{ sort: 'dt_modified', direction: changeDirection }">Dt_modified
                                </Link>
                            </th>

                            <th scope="col">
                                <Link href="/contents/index" :class="defaultClass.modified_by"
                                      :data="{ sort: 'modified_by', direction: changeDirection }">Modified_by
                                </Link>
                            </th>

                            <th class="actions">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="content in contents">
                            <td>{{ content.id }}</td>
                            <td>
                                    <span v-if="content.parent_content">
                                        <Link as="button" class=""
                                              :href="'/contents/view/' + content.parent_content.id">{{ content.parent_content.id }}</Link>
                                    </span>
                            </td>
                            <td>
                                    <span v-if="content.content_type">
                                        <Link as="button" class=""
                                              :href="'/contenttypes/view/' + content.content_type.id">{{ content.content_type.name }}</Link>
                                    </span>
                            </td>

                            <td>
                                {{ content.code64cc }}
                            </td>
                            <td>
                                {{ content.slug }}
                            </td>
                            <td>4{{ content.ordering }}
                            </td>
                            <td>
                                {{ content.show_in_menu }}
                            </td>
                            <td>
                                {{ content.is_active }}
                            </td>
                            <td>
                                {{ content.dt_created }}
                            </td>
                            <td>4{{ content.created_by }}
                            </td>
                            <td>
                                {{ content.dt_modified }}
                            </td>
                            <td>4{{ content.modified_by }}
                            </td>

                            <td class="actions">
                                <Link as="button" method="get" :href="'/contents/view/' + content.id">View</Link>
                                <Link as="button" method="get" :href="'/contents/edit/' + content.id">Edit</Link>
                                <Link as="button" :data="{ csrfToken:csrfToken }" method="get"
                                      :href="'/contents/delete/' + content.id">Delete
                                </Link>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <span v-if="links.length > 1">
               <div class="paginator">
                   <ul class="pagination">
                       <template v-for="(link, key) in links">
                           <li>
                                   <Link as="button" :key="key" class="" :href="link.url">{{ link.label }}</Link>
                           </li>
                       </template>
                   </ul>
               </div>
           </span>
        </div>
    </Layout>
</template>
