<script setup>

import {Link} from '@inertiajs/vue3'
import Layout from "@/Components/Layout.vue";
import sanitizeHtml from "sanitize-html";
import {onMounted, reactive} from "vue";

const props = defineProps({
    parent: [],
    actualites: []
})

const bannerStyle = reactive({
    backgroundImage: "url()",
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: '100% 100%',
    width: '100%',
    height: '300px',
    position: 'relative',
})

const updateStyles = (param) => {
    bannerStyle.backgroundImage = "url(" + param + ")";
}

onMounted(() => {
    updateStyles(props.parent.banner);
});

</script>

<template>
    <Layout>
        <section>
            <div class="section banner-page" :data-background="`${parent.banner}`" :style="bannerStyle">
            <div class="content-wrap pos-relative">
                <div class="container">
                    <div class="col-12 col-md-12">
                        <div class="d-flex bd-highlight mb-2">
                            <div class="title-page">{{ parent.title }}</div>
                        </div>
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb ">
                                <li class="breadcrumb-item">
                                    <Link href="/">Accueil</Link>
                                </li>
                                <li class="breadcrumb-item active" aria-current="page">Actualites</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            </div>
            <div id="class" class="">
                <div class="content-wrap">
                    <div class="container">
                        <div class="row">
                            <div v-for="actualite in actualites" class="col-sm-12 col-md-12 col-lg-4" :key="actualite.id">
                                <div class="rs-actualities mb-5">
                                    <div class="media">
                                        <Link :href="`/actualite/${actualite.slug}`">
                                            <img :src="`${actualite.image}`" alt="" class="img-fluid"/>
                                        </Link>
                                    </div>
                                    <div class="body">
                                        <div class="title">
                                            <Link :href="`/actualite/${actualite.slug}`">
                                                {{ actualite.name }}
                                            </Link>
                                        </div>
                                        <div class="meta-date">{{ actualite.dt_created }}</div>
                                        <div v-html="sanitizeHtml(actualite.brief)"></div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div v-if="actualites.length" class="row mt-5">
                            <div class="col-sm-12 col-md-12">
                                <nav aria-label="Page navigation">
                                    <ul class="pagination">
                                        <li class="page-item"><a class="page-link" href="#">Previous</a>
                                        </li>
                                        <li class="page-item active"><a class="page-link" href="#">1</a>
                                        </li>
                                        <li class="page-item"><a class="page-link" href="#">2</a></li>
                                        <li class="page-item"><a class="page-link" href="#">3</a></li>
                                        <li class="page-item"><a class="page-link" href="#">Next</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>


                </div>
            </div>
            </div>
        </section>
    </Layout>
</template>

<style scoped>

</style>
