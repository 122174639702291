<script setup>

</script>

<template>
    <section class="testmonial bg-gray-light">
        <div class="content-wrap">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-12 col-lg-12">
                        <h2 class="section-heading text-uppercase mt-3 mb-4">Ils nous font confiance</h2>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped>

</style>
