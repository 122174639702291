import {createApp, h} from 'vue'
import {InertiaProgress} from '@inertiajs/progress'
import {createInertiaApp} from '@inertiajs/vue3'

import axios from 'axios'
import VueAxios from 'vue-axios'

import CKEditor from '@ckeditor/ckeditor5-vue';
import PrimeVue from 'primevue/config';

InertiaProgress.init()

createInertiaApp({
    resolve: (name) => require(`./Components/${name}`),
    title: title => title ? `${title}` : '',
    setup({el, App, props, plugin}) {
        createApp({render: () => h(App, props)})
            .use(plugin)
            .use(VueAxios, axios)
            .use(CKEditor)
            .use(PrimeVue, {
                inputStyle: "filled"
            })
            .mount(el);
    },
});
