<script setup>
    import Layout from '../Layout'
    import { Link } from '@inertiajs/vue3'
    import { onMounted, onUnmounted, ref } from 'vue'
    import moment from 'moment'
    import Backend from "@/Components/Backend.vue";

    const props = defineProps({
        csrfToken: String,
        user: [],
    })

    function formatDate(date) {
        return moment(date).format("YYYY-MM-DD")
    }

    onMounted(() => {
        console.log('Component categories/add onMounted hook called')
    })
</script>

<template>
    <Backend>
        <div class="column-responsive column-80">
            <div class="users view content">                <h3>{{user.username}}</h3>
                <table>                                <tr>
                                    <th>Id</th>                                    <td>{{user.id}}</td>
                                </tr>                                <tr>
                                    <th>Username</th>                                    <td>{{user.username}}</td>
                                </tr>                                <tr>
                                    <th>Email</th>                                    <td>{{user.email}}</td>
                                </tr>                                <tr>
                                    <th>First Name</th>                                    <td>{{user.first_name}}</td>
                                </tr>                                <tr>
                                    <th>Last Name</th>                                    <td>{{user.last_name}}</td>
                                </tr>                                <tr>
                                    <th>Secret</th>                                    <td>{{user.secret}}</td>
                                </tr>                                <tr>
                                    <th>Role</th>                                    <td>{{user.role}}</td>
                                </tr>                                <tr>
                                    <th>Additional Data</th>                                    <td>{{user.additional_data}}</td>
                                </tr>                            <tr>
                                <th>Activation Date</th>
                                <td>                                    {{ formatDate(user.activation_date) }}
                                </td>
                            </tr>                            <tr>
                                <th>Tos Date</th>
                                <td>                                    {{ formatDate(user.tos_date) }}
                                </td>
                            </tr>                            <tr>
                                <th>Last Login</th>
                                <td>                                    {{ formatDate(user.last_login) }}
                                </td>
                            </tr>                            <tr>
                                <th>Created</th>
                                <td>                                    {{ formatDate(user.created) }}
                                </td>
                            </tr>                            <tr>
                                <th>Modified</th>
                                <td>                                    {{ formatDate(user.modified) }}
                                </td>
                            </tr>                </table>                    <div class="related">
                        <h4>Social Accounts</h4>
                        <span v-if="user.social_accounts && user.social_accounts.length != 0">
                            <div class="table-responsive">
                                <table>
                                    <tr>                                            <th>Id</th>                                            <th>User Id</th>                                            <th>Provider</th>                                            <th>Username</th>                                            <th>Reference</th>                                            <th>Avatar</th>                                            <th>Description</th>                                            <th>Link</th>                                            <th>Token</th>                                            <th>Token Secret</th>                                            <th>Token Expires</th>                                            <th>Active</th>                                            <th>Data</th>                                            <th>Created</th>                                            <th>Modified</th>                                        <th class="actions">Actions</th>
                                    </tr>
                                    <tr v-for="socialAccounts in user.social_accounts">                                            <td>{{socialAccounts.id}}</td>                                            <td>{{socialAccounts.user_id}}</td>                                            <td>{{socialAccounts.provider}}</td>                                            <td>{{socialAccounts.username}}</td>                                            <td>{{socialAccounts.reference}}</td>                                            <td>{{socialAccounts.avatar}}</td>                                            <td>{{socialAccounts.description}}</td>                                            <td>{{socialAccounts.link}}</td>                                            <td>{{socialAccounts.token}}</td>                                            <td>{{socialAccounts.token_secret}}</td>                                            <td>{{socialAccounts.token_expires}}</td>                                            <td>{{socialAccounts.active}}</td>                                            <td>{{socialAccounts.data}}</td>                                            <td>{{socialAccounts.created}}</td>                                            <td>{{socialAccounts.modified}}</td>                                        <td class="actions">
                                            <Link as="button" :href="'/socialAccounts/view/' + socialAccounts.id">View</Link>
                                            <Link as="button" :href="'/socialAccounts/edit/' + socialAccounts.id">Edit</Link>
                                            <Link as="button" method="post" :href="'/socialAccounts/delete/' + socialAccounts.id">Delete</Link>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </span>
                    </div>            </div>
        </div>
    </Backend>
</template>

