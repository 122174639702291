<script setup>

</script>

<template>
    <div class="section bg-gray-light">
        <div class="content-wrap">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-7">
                        <div class="jumbo-heading text-primary-2">Agir pour des solutions efficaces, adaptées et durables</div>
                        <p class="mt-3 uk21 mb-0">Une longue expérience à votre service</p>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-5">

                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
